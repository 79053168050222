import prosimg from "./assests/images/others/prospectusimg.png";
import pdf from "./assests/images/pdf/ALC_Pune_Prospectus-2023 (1).pdf";
import noticeimg from "./assests/images/others/Revised Date of MAH LL.B 5 Years CET 2024.png";
import noticeimg2 from "./assests/images/others/Syllabus- MAH LL.B 5 Years CET.png";
import noticeimg3 from "./assests/images/others/Academic Excellence Award Presentation Ceremony.pdf";
import noticeimg33 from "./assests/images/others/admission_recieved.png";
import noticeimg5 from "./assests/images/others/Final Merit List BBA LLB 12 Jul 2024.pdf";
import noticeimg55 from "./assests/images/others/final merit list.png";
import noticeimg6 from "./assests/images/others/Admission List -2 1st Year BBA, LL.B 2024-25.pdf";
import noticeimg66 from "./assests/images/others/Admission List -2 1st Year BBA, LL.B 2024-25.png";
import noticeimg7 from "./assests/images/others/Confirmed Admission List 1.pdf";
import noticeimg77 from "./assests/images/others/Confirmed Admissions to 1st Yr BBA, LL.B 2024-25 as on 26_07_2024.png";
import noticeimg8 from "./assests/images/others/Verification List 1st Yr BA, LL.B 2024-25.pdf";
import noticeimg88 from "./assests/images/others/Verification List 1st Yr BA, LL.B 2024-25.png";
import noticeimg9 from "./assests/images/others/BA LLB FINAL MERIT LIST 2024-25.pdf";
import noticeimg99 from "./assests/images/others/BA LLB FINAL MERIT LIST 2024-25.png";
import noticeimg10 from "./assests/images/others/Admission List No 2 BA LLB.pdf";
import noticeimg1010 from "./assests/images/others/Admission List No 2 BA LLB.png";
import noticeimg11 from "./assests/images/others/CET_Schedule.pdf";
import noticeimg1111 from "./assests/images/others/cetShedule.png";
import noticeimg1212 from "./assests/images/others/registrationforLLB.jpg";
import pdf1 from "./assests/images/others/Questindicia.pdf";
import noticepdf from "./assests/images/others/Notice_LLB-5-Yrs-CET_3rd-Extension.pdf";
import noticepdf2 from "./assests/images/others/Revised_Due-to-electionsL28032024.pdf";

export const allSteps = [
  {
    title: "1. Apply online for MAH LLB (5 YEARS) CET 2025",
    steps: [
      {
        id: 1,
        step: "Visit website https://cetcell.mahacet.org Click on the MAH LLB (5 Years) CET 2025 (Integrated Course) and to the webpage https://cetcell.mahacet.org with all the links related to Mock Test, Application form, notices and Information Brochure",
      },
      {
        id: 2,
        step: "Fill up the form online. ",
      },
      {
        id: 3,
        step: "Complete all the details, make the payment and then submit the form.",
      },
      {
        id: 4,
        step: "Save a copy of the CET Application Form on your Device. A copy of the form will be required to be uploaded for Application to ALC Pune.",
      },
      {
        id: 5,
        step: "Filling up of Online Application Form as per ALC Pune admission procedure after CET Registration and completing the application details including uploading of all required documents before the CET result is MANDATORY; Forms found incomplete after the CET result declaration may be rejected.",
      },
    ],
  },
  {
    title:
      "2. Kindly complete the Online Admission procedure in the following steps:",
    steps: [
      {
        id: 1,
        step: "New Applicants for admission to Army Law College, Pune to first read the FAQs on Admission and ALC Prospectus to confirm their eligibility as wards of Army/ Navy/ Air Force personnel of Indian Army. Register Here to create a New Login ID and password for Online Admission (Do not create a duplicate login ID)",
      },
      {
        id: 2,
        step: "Navy/Air Force seats reserved for B.B.A LL.B Course as per policy",
      },
      {
        id: 3,
        step: "After successful registration, you will receive a login ID and password on the given email address. Existing applicants Click here and use the same login ID and password to complete the following steps.",
      },
      {
        id: 4,
        step: "Step I: Fill in your personal information as asked in the application form correctly in “Personal Information” link.",
      },
      {
        id: 5,
        step: " Step II:Upload all the mandatory documents as per the list displayed in “Upload Documents” link. In case you cannot upload all the documents in single session, you may re-login before the cut off date for submission of the Application and can upload the document as required. No changes in application form can be made after approval of submitted application. Incorrect details in application form and fake documents uploaded by an applicant will render his/her application void.",
      },
      {
        id: 6,
        step: " Step III: After uploading all the mandatory documents you can click on “Payment” link. It will take you to the SBI Payment gateway. Payment of Application fee (Rs. 1000/- must be completed for acceptance of the Application Form.",
      },
      {
        id: 7,
        step: "Step IV: You will have to enter the payment details (i.e. the UTR/Transaction ID of your payment) in this form to be verified by ALC Pune.",
      },
      {
        id: 8,
        step: " Step V: On the verification of your application fee payment details by ALC Pune, you will be able to print your application form by clicking on “Print Application Form”.",
      },
    ],
  },
  {
    title: "3. Online Counselling & Admission Confirmation:",
    content:
      "ALC Pune publishes ‘Provisional Merit List’ and ‘Final Merit List’ in r/o Candidates (Army wards). Merit Lists are drawn as per CET score and names are notified in ‘Admission List’ after proper verification of eligibility criteria. Order of merit is as per the marks scored in MAH LL.B 5 Yr CET and factoring of additional weightage for Gallantry Awards/ Ward of War Widow, if applicable, as per the Prospectus. Qualifying exam/ 12th Std Exam percentage is used to decide merit among candidates with same CET score. Candidates notified in a specified Admission List shall be eligible to complete online counselling Steps VI & Step VII within the specified date and time. ",
    steps: [
      {
        id: 1,
        step: "Step VI: Online Counselling / Indication of Choice: On the declaration of an Admission List by ALC Pune, if your name appears in the declared admission list and if you wish to accept the admission in 1st Yr BBA LL.B/BA LL.B at ALC Pune, please login for Online Counselling using the existing credentials as a candidate in application process and click on “Accept Admission” menu. Click on either the “I Accept” or “I reject” button on this form. (vacant seats after the cut-off time shall pass on to candidates next in Final Merit List). ",
      },
      {
        id: 2,
        step: " Step VII: Submission of Fee Payment Details: Pay the notified New Admission Fee and enter the New Admission Fee payment transaction details in this step ( ensure the admission is accepted in previous Step VI before you submit the fee transaction details).  ",
      },
      {
        id: 3,
        step: "Step VIII: On the verification of your new admission fee payment details by ALC Pune Admission Committee, you shall be able to print your Admission Confirmation Slip by clicking on “Print Admission Confirmation”.",
      },
    ],
  },
];

export const coursesData = [
  {
    id: 11,
    img: noticeimg1212,
    link: noticeimg1212,
    text: "Registration For LLB 5 Yrs.  CET Examination(A.Y. 2025-26)",
  },
  {
    id: 10,
    img: noticeimg1111,
    link: noticeimg11,
    text: "CET_Schedule Notice",
  },
  {
    id: 1,
    img: noticeimg1010,
    link: noticeimg10,
    text: "Admission List-2 of 1st Year BA LL.B 2024-25 is posted under Admission Notices",
  },
  {
    id: 2,
    img: noticeimg99,
    link: noticeimg9,
    text: "Final Merit List & Admission List -1 1st Year BA, LL.B 2024-25",
  },
  {
    id: 3,
    img: noticeimg77,
    link: noticeimg7,
    text: "Confirmed Admissions List 1st",
  },
  {
    id: 4,
    img: noticeimg66,
    link: noticeimg6,
    text: "Addmission List -2 1st Year BBA, LL.B 2024-25",
  },
  {
    id: 5,
    img: noticeimg88,
    link: noticeimg8,
    text: "Verification List 1st Yr BA, LL.B 2024-25",
  },
  {
    id: 6,
    img: noticeimg55,
    link: noticeimg5,
    text: "Final Merit List BBA LLB 12 Jul 2024",
  },
  {
    id: 7,
    img: noticeimg33,
    link: noticeimg3,
    text: "Admission Circular 1/2024 Provisional Merit List Dates.",
  },

  {
    id: 8,
    img: noticeimg,
    link: noticeimg,
    text: "Revised Date of MAH LL.B 5 Years CET 2024",
  },
  {
    id: 9,
    img: noticeimg2,
    link: noticeimg2,
    text: "Syllabus- MAH LL.B 5 Years CET",
  },
];

export const ArchiveData = [
  {
    text: "Syllabus and Marking Scheme for LL.B 5Yr CET",
  },
  {
    text: "Brochure Mah LL.B 5Yr CET 2024",
  },
  {
    text: "Registration Notice Mah LL.B 5Yr CET 2024",
  },
  {
    text: "User Manual Mah LL.B 5Yr CET 2024",
  },
  {
    text: "Revised Date of MAH LL.B 5 Years CET 2024",
  },
  {
    text: "Syllabus- MAH LL.B 5 Years CET",
  },
];

export const FaqData = [
  {
    id: 1,
    que: " What is the affiliation of Army Law College, Pune & where is it located?",
    ans: "Army Law College, an Institution of Army Welfare Education Society, New Delhi was established under a special order dated 21 March 2018 of the Government of Maharashtra for conducting Integrated 5-year BBA LL.B as a permanent non-grant College under the affiliation of Savitribai Phule Pune University and approval of the Bar Council of India for children of serving and retired Army personnel including battle casualties & war widows. The institution is located on Pune-Mumbai National Highway (NH-4) at Kanhe (Pune-412106), which is approx. 40 Kms from Pune Railway Station and about 18 Kms from Lonavala.",
  },
  {
    id: 2,
    que: " Is admission to BBA LL.B at ALC Pune open to all?",
    ans: "Admission to BBA LL.B/ BA LL.B at ALC, Pune is fully reserved for children of Army Personnel, who have rendered minimum 10 years of service in the Indian Army and battle casualties & war widows.",
  },
  {
    id: 3,
    que: " Can outside Maharashtra candidates apply for admission to ALC, Pune?",
    ans: "Yes, eligible children of Army personnel outside Maharashtra are eligible to apply for admission to ALC, Pune.",
  },
  {
    id: 4,
    que: " Who conducts admission test for the admission to 1st year BBA LL.B at ALC, Pune?",
    ans: "The prescribed admission test for admission to 1st year BBA LL.B/ BA LL.B at ALC is MAH LL.B (5 Years) CET Exam conducted by CET Cell, Dept of Higher & Technical Education, Government of Maharashtra. Please visit website www.mahacet.org and read the MAH CET Brochure.",
  },
  {
    id: 5,
    que: " How is registration carried out for MAH LL.B (5 Years) CET and for ALC , Pune?",
    ans: "ONLINE registration is done in two stages, firstly a candidate has to register online for MAH LL.B (5 Years) CET in MAH CET website www.dhepune.gov.in / www.mahacet.org . MAH CET registration form should be saved. Secondly, using the MAH CET Registration Number the candidate has to register at ALC, Pune website https://alcpune.com/  . Please read general instructions carefully before entering the registration process online. All requisite data and certificates/photo should be kept ready at hand to avoid partial/unsuccessful registration. Registration fee for both the process is payable separately, online at respective web sites.  ",
  },
  {
    id: 6,
    que: " Is it mandatory to register for both at MAH CET portal and ALC, Pune website?",
    ans: "Yes. Only candidates who register for MAH LL.B (5Years) CET are eligible to appear in the online admission test. It is also mandatory to register for admission to ALC, Pune since the counselling and selection of eligible candidates i.e. wards of serving/ retired Army personnel are done by the College Admission Committee after verification of prescribed documents & records at the time of registration and counselling. 100% admissions to ALC Pune are on the basis of marks obtained in MAH LL.B (5Years) CET in the year of admission.",
  },
  {
    id: 7,
    que: " Is there any quota in new admissions allotted to wards of non-Army personnel?",
    ans: "No. As of now the Institution offers admission only to wards of serving and retired Army personnel.",
  },
  {
    id: 8,
    que: " What is the eligibility criteria for applying for MAH CET/ ALC, Pune?",
    ans: "The candidate should have passed 12th Class or equivalent Senior School Certificate Exam conducted by C.B.S.E. or State Board or equivalent exam conducted by I.C.S.E. or Intermediate Exam conducted by a recognized institution under State Government / Central Government in India with minimum 45%. ALC, Pune offers admission to children of Army personnel who have attained minimum 50% marks in qualifying exam. Candidates who have appeared for the qualifying exam may apply for online MAH LL.B (5 Years) CET & 1st Year BBA LL.B/ BA LL.B at ALC Pune, however their admission will be subject to passing the qualifying exam as above and clearing the admission test.",
  },
  {
    id: 9,
    que: "Is there any management quota for admission to 1st Year BBA LL.B/ BA LL.B? ",
    ans: "No. There is no management quota. Admission is given to eligible candidates, strictly as per the order of merit in admission test & eligibility criteria, out of the children of Army personnel registered for admissions at ALC Pune. 100% admissions to ALC Pune.",
  },
  {
    id: 10,
    que: "What are the cut-off dates for applying for admission to ALC, Pune?",
    ans: "The advertisements for admissions to Army Law College, Pune are published on website and in newspapers by January each year. The Notification of Mah CET Cell in r/o MAH LL.B (5Years) CET Exam is published in Mah CET Portal www.mahcet.org normally by 2nd week of January. Online registration for MAH LL.B (5Years) CET commences in 3rd/4th week of January upto 2nd week of March or as per cut-off date notified. MAH LL.B (5Years) CET Exam is conducted online in 2nd/3rd week of April/ as notified. Results of MAH LL.B (5 Years) CET are likely to be declared in June/ or as notified. Cut-off date for closing registration for 1st Year BBA LL.B/ BA LL.B by ALC, Pune is generally the next day from date of declaration of MAH CET results/ as notified. Candidates are advised to keep updated with the notices/ changes posted on ALC Pune website https://alcpune.com/ Mah CET Portal https://cetcell.mahacet.org/CET_landing_page_2024/ or https://llb5cet2024.mahacet.org/StaticPages/HomePage",
  },
  {
    id: 11,
    que: "Is there any cut-off age limit for applying to 1st year BBA LL.B/BA LL.B ? ",
    ans: "Please refer to MAH LL.B 5 Year CET 2024 Brochure for the prescribed age criteria in r/o applicants for MAH LL.B 5Yr CET 2024. For more details please log on to Mah CET Portal https://cetcell.mahacet.org/CET_landing_page_2024/ or https://llb5cet2024.mahacet.org/StaticPages/HomePage",
  },
  {
    id: 12,
    que: " What all documents are verified in respect of bonafide as ward of Army Personnel?",
    ans: "The Dependent Certificate as per the applicable format – Certificate 1(Serving/Retired Army personnel having rendered 10 Years’ continuous service in Army/Killed in Action/ Died in War during Service/Medically boarded out with Pension), Certificate 2 (Step Children of Army Personnel Born from wedlock/ Adopted Children of Army Personnel who have been adopted at least 5 Years prior to commencement of course) / Certificate 3 (Children of Army Medical Corps/ AD Corps personnel with minimum 10 years Army service, not permanently seconded to other Service & presently serving with Air Force / Naval Medical establishment & MNS/APS/TA personnel who served minimum 10 years in Army unit/Est).  In addition, original copy of Serving/ Retired Identity card/ PPO/ Kindred Roll/ Pension Book/ Medical Board Proceedings in case of Medically Boarded out/ Dependent Certificate issued by AG’s Branch, Dte Gen of M & P (Coord)/ Legal papers incase adopted ward will be checked during verification.  Besides original marksheet of 10th, 12th, College/ School Leaving Certificate, Migration Certificate, Registration & Scorecard of MAH CET Exam, Registration for Admission to ALC Pune will also be checked. ",
  },
  {
    id: 13,
    que: "Can serving Army personnel apply for the BBA LL.B/ BA LL.B  Course?",
    ans: "No. ALC Pune offers admission only to children of serving & retired Army Personnel.",
  },
  {
    id: 14,
    que: "How many vacancies exist for yearly new admissions to Army Law College, Pune?",
    ans: "Annual intake in 1st Year BBA LL.B & BA LL.B Courses is 60 students each i.e., total 120 Seats.",
  },
  {
    id: 15,
    que: "Is there any quota fixed in admission for boys and girls separately?  ",
    ans: "No. All admissions are strictly on the basis of merit in MAH LL.B (5 Years) CET Exam.",
  },
  {
    id: 16,
    que: "Does Institution conduct three year LL.B course also?",
    ans: "No. Only 5 Year Integrated BBA LL.B/ BA LL.B Courses are conducted at ALC Pune.",
  },
  {
    id: 17,
    que: "How are seats allotted for 1st Year BBA LL.B /BA LL.B at ALC Pune? ",
    ans: "The admission to ALC is 100% reserve for wards of serving and retired Army solders /war widows. The seats for 1st Year BBA LL.B/ BA LL.B are allotted strictly on merit as per marks scored in entrance test conducted by the Government of Maharashtra, i.e. MAH LL.B (5 Years) CET Exam. As a special provision the Government of Maharashtra has allowed the Institution to conduct admissions on the basis of merit, hence, the Institution publishes a merit list of candidates registered with Army Law College, Pune as per eligibility criteria and hold counselling for candidates shortlisted as per merit. The dates for registration and declaration of Provisional Merit List/ Final Merit List/ Admission List/ Reserve List are notified on the College website https://alcpune.com/.",
  },
  {
    id: 18,
    que: "Can non-commerce students apply for the 1st Year BBA LL.B Course?  ",
    ans: "Yes, the admission to BBA LL.B is open also to non - commerce i.e. Science /Arts stream/ other stream candidates provided they fulfil the eligibility criteria. ",
  },
  {
    id: 19,
    que: "What is the medium of instruction at ALC, Pune?",
    ans: "The medium of instruction is compulsorily in English. ",
  },
  {
    id: 20,
    que: "Are there any scholarships available?",
    ans: [
      "(a) ESSA : Please refer detailed instructions in r/o Educational Scholarship Scheme for Serving Army Personnel- ESSA on website of Army Welfare Education Society www.awesindia.com . ",
      "(b) Educational Concessions admissible to the children of Armed Forces personnel killed / missing or permanently disabled in 1962, 1965, 1971 wars, Op PAWAN and MEGHDOOT have now been extended to the children of the Armed Forces personnel killed / declared missing or permanently disabled during all operations post Operation MEGHDOOT in India and abroad including Counter Insurgency operations, vide GOI, MOD letter No. 9 (1)/703/Edu Concessions / D (Res) dated 06 Aug 03. The children of Armed Forces Personnel Missing/ Disabled/ Killed in Action are entitled to claim concessions from Department of Ex-servicemen Welfare @ reimbursement of Full Tuition Fees & Hostel Charges and certain amounts towards cost of books, cost of uniform and clothing as specified in GoI, MoD letter No. 6(1)/2009/Edu. Concession/D(Res-II) dated 13 September 2017 read with MoD Letter No. 6(1)/2009/Edu. Concession/D(Res-II) dated 23 March 2018. Please refer concerned circulars on website www.desw.gov.in/circulars . The claims in r/o the educational concessions are submitted to Ceremonial & Welfare Dte / CW-3, AG’s Branch, Army Headquarters, South Block, New Delhi – 110 011 for reimbursement.",
      "(c) PM Scholarship Scheme: The ‘Prime Minister’s Scholarship Scheme (PMSS) is being implemented to encourage technical and post-graduate education for the widows and wards of the deceased/ex-service personnel of Armed Forces, Para Military Forces and Railway Protection Force. The scheme is funded out of National Defence Fund administered by Prime Minister’s Office. Scholarships are available for education at various technical institutions (medical, dental, veterinary, engineering, MBA, MCA and other equivalent technical institutions having AICTE/UGC approval). Please refer concerned circulars on website PM Scholarship Scheme (PMSS) | Department of Ex-servicemen Welfare | Ministry of Defence | GoI (desw.gov.in)",
      "(d) Rajput Regiment Best Student Rolling Trophy & Scholarship.Awarded to the overall best student in an academic year. The Award includes the Rolling Trophy and Scholarship of Rs. 5000/- ",
    ],
  },
  {
    id: 21,
    que: "What all academic infrastructure is available at ALC?",
    ans: "Since its establishment in 2018, the Army Law College, Pune campus has meticulously planned growth of its College infrastructure. The institution has all the academic facilities including Takshshila Library with over 5000 books including all text books of syllabus and reference books, online and print journal, legal & management online databases, media lab, campus WiFi, Moot Court & Legal Aid Cell, Conference Hall, Seminar Hall, Amphitheatre and smart class rooms for all batches. The institution has adequate sports facilities to include indoor games and gym separate for each hostel and synthetic surface basketball court, volleyball court, badminton court and physical training implements such as open gymnasium besides matching administrative and hostel facilities with water treatment plant and 100 KVA Power-backup Generator. New hostels for boys and girls, 450 seating capacity auditorium, academic cum administrative block with all new class rooms, conference room, 150 seating moot court have been added besides other campus facilities. The institution has its independent water supply, water treatment plant, solar energy power augmentation scheme, sewage treatment plant and well-planned internal roads. The campus security is 24x7 with integrated CCTV & communication system. ",
  },
  {
    id: 22,
    que: "What is the hostel facility at Army Law College, Pune? ",
    ans: "The Army Law College, Pune is 100% residential institution. Separate hostels exist for boys and girls. Both Boys & Girls Hostels have 2/3 sharing rooms. Entire hostel room furniture and furnishing is provided by the Institution. Students have to get their personal items as notified in Prospectus & Joining Instructions. ",
  },
  {
    id: 23,
    que: "Is outliving in PG/ Own house for local students permitted? ",
    ans: "No. Hostel accommodation exists for 100% students and on compassionate grounds up to 10% day scholars may be permitted by the Management. ",
  },
  {
    id: 24,
    que: "What is the arrangement for students’ meals? ",
    ans: "Students mess with dining room and kitchen exist in each hostel. Catering services are outsourced by the Administration for running the messes through annual catering contract. Meals are provided as per timings. ",
  },
  {
    id: 25,
    que: "What is the arrangement for laundry in ALC campus? ",
    ans: "The laundry services are outsourced from local vendor through tie- up contract. The charges are notified and students are required to pay their monthly laundry bills individually. Washing machines are also provided in hostels. ",
  },
  {
    id: 26,
    que: "What is the means of transport from ALC Campus to Pune City?",
    ans: "Kanhe is well connected by rail and road. Kanhe railway station is just 1.5 Kms from the College. Pune- Lonavala local trains (EMU) are available every 1.5 hour interval from 6:00 A.M. to 11:00 P.M. Also, Pune-Nigdi city buses operate through every hour between 6:30 A.M. to 9:55 P.M. For official academic/ student activity trips College has bus and light vehicle. A CNG Van exists for emergency evacuation of sick students. ",
  },
  {
    id: 27,
    que: "Can students keep their personal motorcycles?",
    ans: "Considering that the College is located on busy National highway, private motorcycles/ motor vehicles are not permitted to be kept by students.",
  },
  {
    id: 28,
    que: "What are the medical facilities available in campus & nearby?",
    ans: "A medical Room with Nursing Assistant and visiting doctor is available in campus. It has all first aid treatment facility. Cases requiring higher treatment are facilitated review by specialist at Gramin General Hospital, Maval at Kanhe (400 M) / MIMER Medical college, Talegaon (9 Kms) / MH Kirkee (41 Kms) / CH Pune (48 Kms). A patient bus is sent once a week to MH/ CH for OPD. ",
  },
  {
    id: 29,
    que: "What is the fee structure for BBA LL.B/ BA LL.B Courses?",
    ans: "New admission fee is notified in the ALC Prospectus a copy of which is posted under ‘Admissions’ in College website. It consists of Onetime Charges including security deposits, Annual Charges (annual) and Hostel Fee (annual). Please refer latest information on the College website https://alcpune.com/  .",
  },
  {
    id: 30,
    que: "What is the likely yearly enhancement of fee?",
    ans: "Fee may generally be enhanced as per AWES approval to the extent of 10%, annually.",
  },
  {
    id: 31,
    que: "How is fee deposited to Institution? ",
    ans: "Fee can be deposited online through SBI Collect Fee Payment Gateway incorporated in college website https://alcpune.com/  . Other methods such as NEFT/RTGS/Bank Transfer/ Bank Draft deposited to fee collection account can also be followed in coordination with the Institution.",
  },
  {
    id: 32,
    que: "Can the fee be deposited in instalments?",
    ans: "The procedure of fee payment is given in the College Prospectus each year. Generally, the fee shall be paid in full at the time of admission/ by cut-off date prescribed by the Institution. The provision of fee payment in instalment may be accepted on merit of the case and with due approval of competent authority.",
  },
  {
    id: 33,
    que: "Is it mandatory to register for Army Law College, Pune for admissions separately?",
    ans: "Yes, the candidate must register for admission to ALC Pune at website https://alcpune.com/  using his/her MAH CET Registration Number after filling in the application form for admission Test i.e. MAH LL.B (5 Years) CET Exam on Maharashtra State Government, Admission Portal of CET Cell www.mahacet.org.",
  },
  {
    id: 34,
    que: "Is lateral entry of candidate after BBA/ BA Course in Maharashtra or outside permitted in 3rd year?",
    ans: "No. Lateral entry in Integrated BBA LL.B Course is not permitted.",
  },
  {
    id: 35,
    que: "Would a student leaving BBA LL.B/ BA LL.B course after 2nd /3rd Year be eligible to award of BBA/ BA ?",
    ans: "No. Please refer academic rules on Savitribai Phule Pune University website https://alcpune.com/  .",
  },
  {
    id: 36,
    que: "Does BBA LL.B / BA LL.B Course at ALC, Pune result in employment in Army JAG Branch? ",
    ans: "BBA LL.B Course at ALC as such is not linked to direct employment in Army. The placement avenues for careers in Legal/ Corporate/ and Armed Forces are planned by the Placement Cell and the students passing out will have to pass PG CLAT, register as Advocate and compete with other candidates for JAG Branch Officer Cadre. Minimum 20 weeks of internship is compulsory as per BCI.",
  },
  {
    id: 37,
    que: "Is BBA LL.B the right course for diversified career opportunities?",
    ans: "The BBA LL.B Course offers a diversified career opportunities in Law Firms/ Corporates/ Services Sector, primarily due to integration of Management and Law in its course content. On the other hand BA LL.B is a conventional law course to follow careers in legal sphere.",
  },
  {
    id: 38,
    que: "Is there a prescribed college uniform?",
    ans: "Yes. The College in terms of Bar Council Rules for Legal Education has laid down the college uniform as prescribed in Prospectus (white Shirt, Black Trouser, Black Shoes and College Tie).",
  },
  {
    id: 39,
    que: "What are the placement avenues to students of BBA LL.B/ BA LL.B  course?",
    ans: "The Placement Cell of the Institution provides necessary support for internship and on the job training programme in Legal and Commercial establishments/ firms. An effort is being made to ensure widespread dissemination of College Brochure and students’ profiles for placement and internship with leading firms/companies. Approximate 60-75% students pursue career in law while others go for higher studies and competitions",
  },
  {
    id: 40,
    que: "What is the cancellation policy in r/o admissions to 1st Year BBA LL.B/ BA LL.B?",
    ans: "Admission once confirmed by the Institution and subsequent withdrawal will be subject to terms as notified in Prospectus of Army Law College, Pune. The institute will notify the cut-off date for withdrawal of admission on the College website https://alcpune.com/  and withdrawal before the cut-off date shall be entitled to full fee refund and a processing charge as prescribed will be deducted. Withdrawal from Admissions to 1st Year BBA LL.B / BA LL.B after the cut-off date shall be subject to fee deductions as prescribed by Academic Rules of Savitribai Phule Pune University notified on the University website www.unipune.ac.in .",
  },
];
