import React from "react";
import point from "../../assests/images/others/point (1).png";
import { allSteps } from "../../AddmissionData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faUser } from "@fortawesome/free-regular-svg-icons";

export default function Procedure() {

  const renderStepWithLinks = (step) => {
    // Define the links
    const hiddenLink = "https://admissionalc.edupluscampus.com/";
    const registerLink = "https://admissionalc.edupluscampus.com/new_user_signup/"; // replace with your actual link
  
    // Split the step string by "Click here", "Register Here" and URLs using a regular expression
    const parts = step.split(/(Click here|Register Here|https?:\/\/\S+)/);
  
    // Map over the parts of the step string
    const elements = parts.map((part, index) => {
      if (part === "Click here") {
        // If the part is "Click here", render it as a hyperlink to the hidden link
        return (
          <a key={index} href={hiddenLink} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else if (part === "Register Here") {
        // If the part is "Register Here", render it as a hyperlink to the register link
        return (
          <a key={index} href={registerLink} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else if (part.startsWith("http")) {
        // If the part is a URL and it's not the hidden link or the register link, render it as a hyperlink
        if (part !== hiddenLink && part !== registerLink) {
          return (
            <a key={index} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        }
      } else {
        // If the part is not "Click here", "Register Here" or a URL, render it as plain text
        return part;
      }
    });
  
    // Join the elements into a single JSX element
    return <>{elements}</>;
  };
  return (
    <>
      <div className="container" id="procedure">
        <div className="mt-5 d-flex justify-content-center flex-column align-items-center ">
          <p className="tableName col-9 text-center ">
            Admission to ALC is on the basis of marks obtained in MAH LLB (5
            YEARS) CET 2025. The applicant must apply first for MAH LLB (5
            YEARS) CET 2025.
          </p>
          <p className="progtext text-center col-10">
            After enrolling for MAH LLB (5 YEARS) CET 2025, the applicant can
            fill the ALC Application Form. The applicant must enter his/her
            correct MAH LLB (5 YEARS) CET 2025 Registration Number while filling
            up the ALC Application Form.
          </p>
        </div>
        {allSteps.map((section, index) => (
          <div key={index} className="mt-5">
            <p className="progsubhead">{section.title}</p>
            <p className="aboutp d-flex flex-column justify">
              <small>{section.content}</small>
            </p>
            {section.steps.map((rule, i) => (
              <ul key={rule.id} className="mt-4 px-0">
                <li className="d-flex">
                  <img src={point} alt="" className="hand img-fluid col-1" />
                  <p className="px-3 aboutp mb-0 procedure-info">
                    <small>{renderStepWithLinks(rule.step)}</small>
                  </p>
                </li>
              </ul>
            ))}
          </div>
        ))}
      </div>
      <div className=" erp1 ">
        <a href="https://admissionalc.edupluscampus.com/" target="_blank">
          <button className=" d-flex align-items-center ">
            <FontAwesomeIcon icon={faCircleUser} className="me-2" />
            <span>
              <small>Admission Login</small>
            </span>
          </button>
        </a>
      </div>
    </>
  );
}
